import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { Image } from "../gatsby-images/image"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { connect } from "react-redux"

const Details = ({ windowWidth, roomPageAnimation3sDelay }) => {
  let imagesWrapper = useRef()
  let mainSection = useRef(``)
  let images = useRef([])
  let biggestImage = useRef()

  const addToImagesWrapper = el => {
    if (el && !imagesWrapper.current.includes(el)) {
      imagesWrapper.current.push(el)
    }
  }

  const addToImages = el => {
    if (el && !images.current.includes(el)) {
      images.current.push(el)
    }
  }

  let animation
  let wrapWidth
  let wrapVal

  const [minHeight, setMinHeight] = useState({ updated: false, height: 0 })
  //tweens
  let imagesScrollerTriggerTween = useRef(null)
  let imagesMoverTween = useRef(null)

  const createTweens = (biggestImageHeight, mainSectionHeight) => {}

  useEffect(() => {
    imagesScrollerTriggerTween.current &&
      imagesScrollerTriggerTween.current.kill()
    imagesMoverTween.current && imagesMoverTween.current.kill()

    const imgArrConst = images.current
    const movableImageWrapper = imagesWrapper.current
    const mainSectionMarkers = mainSection
    const numBoxes = imgArrConst.length

    //set height of container
    const biggestImageHeight = biggestImage.getBoundingClientRect().height
    const mainSectionHeight = mainSection.getBoundingClientRect().height

    if (!minHeight.updated) {
      setMinHeight({ updated: true, height: biggestImageHeight })
    }

    wrapWidth = window.innerWidth
    wrapVal = gsap.utils.wrap(0, wrapWidth)

    let additionalX = { val: 0 }
    let additionalXAnim
    let offset = 0
    const screenWidth = window.innerWidth

    gsap.set(imgArrConst, { left: screenWidth })
    let imagesPosition = 0

    let totalW = 0

    let imgWidths = {}
    setTimeout(
      () => {
        imgArrConst.map((img, i) => {
          let imgWidth = img.getBoundingClientRect().width
          let leftPosition = imagesPosition
          gsap.to(img, { left: leftPosition })
          imagesPosition += imgWidth
          //calculating total imgs width
          totalW += imgWidth
          //create hash of width per index
          imgWidths[i] = totalW
        })

        imagesMoverTween.current = gsap.to(imgArrConst, {
          x: `-=${totalW}`,
          duration: 60,
          repeat: -1,
          ease: "none",
          modifiers: {
            x: (x, arr) => {
              const imgIndex = arr.getAttribute("data-index")
              let leftTravel = -imgWidths[imgIndex]

              let rightOverflow = totalW + leftTravel
              var mod = gsap.utils.wrap(leftTravel, rightOverflow)
              offset += additionalX.val

              // x = x + offset
              return `${mod(parseFloat(x) + offset)}px`
            },
          },
        })

        imagesScrollerTriggerTween.current = ScrollTrigger.create({
          trigger: imagesWrapper,
          start: "top 80%",
          end: "bottom 50%",
          // markers: true,
          onUpdate: function (self) {
            const velocity = self.getVelocity()

            if (velocity > 0) {
              if (additionalXAnim) additionalXAnim.kill()

              additionalX.val = -velocity / 500
              additionalXAnim = gsap.to(additionalX, { val: 0 })
            }
          },
        })
      },
      roomPageAnimation3sDelay ? 2000 : 2000
    )
  }, [windowWidth])

  useEffect(() => {
    return () => {
      imagesScrollerTriggerTween.current &&
        imagesScrollerTriggerTween.current.kill()
      imagesMoverTween.current && imagesMoverTween.current.kill()
    }
  }, [])

  return (
    <>
      {/* <div style={{fontSize: '10vw', marginBottom: '10vw'}} className="text-center">Details</div> */}
      <div
        ref={el => (mainSection = el)}
        className="details-main-section"
        style={{ height: "50vw" }}
      >
        <div className="details-rooms" style={{ height: "50vw" }}>
          <div className="images-wrapper" ref={el => (imagesWrapper = el)}>
            <div
              className="image landscape"
              ref={el => addToImages(el)}
              data-index="0"
            >
              <Image src="peinture-rooms-details" />
            </div>
            <div
              className="image small-portrait"
              ref={el => addToImages(el)}
              data-index="1"
            >
              <Image src="chambre-6-mirror-rooms-details" />
            </div>
            <div
              className="image big-portrait"
              data-index="2"
              ref={el => {
                addToImages(el)
                biggestImage = el
              }}
            >
              <Image src="chambre-3-mirror-rooms-details" />
            </div>
            <div
              className="image small-portrait"
              data-index="3"
              ref={el => addToImages(el)}
            >
              <Image src="fragonard-portrait-rooms-details" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ windowWidth, roomPageAnimation3sDelay }) => {
  return { windowWidth, roomPageAnimation3sDelay }
}

export default connect(mapStateToProps, null)(Details)
