import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import Introduction from "../components/rooms/Introduction"
import NewBanner from "../components/rooms/NewBanner"
import RoomsList from "../components/rooms/RoomsList"
import SEO from "../components/seo"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { console } from "globalthis/implementation"
import NewRoomComponent from "../components/newrooms/NewRoomComponent"

const langSeo = {
  fr: {
    title: "Hôtel Au Charme Rabelaisien **** | Les Chambres",
    description:
      "Hotel Au Charme Rabelaisien vous présente ses chambres, confortables, raffinnées avec de  somptueuses vues sur le jardin, Amboise et son Château",
  },
  en: {
    title: "Hotel Au Charme Rabelaisien **** | Rooms",
    description:
      "Hotel Au Charme Rabelaisien presents its rooms, comfortable, refined with sumptuous views of the garden, Amboise and its Château",
  },
  ru: {
    title: "Hotel Au Charme Rabelaisien **** | Les Chambres",
    description:
      "Hotel Au Charme Rabelaisien presents its rooms, comfortable, refined with sumptuous views of the garden, Amboise and its Château",
  },
}

const RoomsPage = ({
  updateNavColor,
  navColor,
  hideTransitionBanner,
  language,
  roomListColor,
  updateIsRoomsPage,
  updateRoomsListColor,
  scrollOffset,
  scrolltriggerReady,
  updateShowArrowDown,
}) => {
  let roomsListContainer = useRef(null)
  let roomsContainer = useRef(null)
  let urlLanguage = "fr"
  let tempScrollTriggerRef = useRef(null)

  if (typeof window !== "undefined") {
    const url = window.location.pathname

    if (url.includes("fr")) {
      urlLanguage = "fr"
    } else {
      urlLanguage = "en"
    }
  }

  //state
  const [pageHeight, setPageHeight] = useState("")

  //show arrow down
  useEffect(() => {
    if (scrolltriggerReady) {
      if (pageHeight && pageHeight - scrollOffset < window.innerHeight + 100) {
        updateShowArrowDown(false)
      } else {
        updateShowArrowDown(true)
      }
    }
  }, [scrollOffset, scrolltriggerReady])

  useEffect(() => {
    updateIsRoomsPage(true)
    updateRoomsListColor("#EFE8E3")
    navColor !== "black" && updateNavColor()
    setTimeout(() => {
      hideTransitionBanner()
    }, 2000)

    const tempScrollTrigger = ScrollTrigger.create({ id: "temp-rooms" })
    setPageHeight(tempScrollTrigger["scroller"]["scrollHeight"])
    tempScrollTriggerRef.current = tempScrollTrigger

    return () => {
      updateIsRoomsPage(false)
      updateRoomsListColor("white")
      tempScrollTriggerRef.current && tempScrollTriggerRef.current.kill()
    }
  }, [])

  return (
    <div className="rooms-container" ref={el => (roomsContainer = el)}>
      <SEO
        title={langSeo[urlLanguage]["title"]}
        description={langSeo[urlLanguage]["description"]}
      />
      <NewBanner />
      <Introduction />
      <NewRoomComponent />
      {/* <div
                ref={el => roomsListContainer = el}
            >
                <RoomsList 
                    backgroundColor={roomListColor}
                />
            </div> */}
    </div>
  )
}
const mapStateToProps = ({
  navColor,
  roomListColor,
  language,
  scrollOffset,
  scrolltriggerReady,
}) => {
  return { navColor, roomListColor, language, scrollOffset, scrolltriggerReady }
}

const mapDispatchToProps = dispatch => {
  return {
    updateNavColor: () =>
      dispatch({ type: `UPDATE_NAV_COLOR`, payload: "black" }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    updateIsRoomsPage: bool =>
      dispatch({ type: "IS_ROOMS_PAGE", payload: bool }),
    updateRoomsListColor: color =>
      dispatch({ type: "UPDATE_ROOM_LIST_COLOR", payload: color }),
    updateShowArrowDown: bool =>
      dispatch({ type: "UPDATE_SHOW_ARROW_DOWN", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsPage)
