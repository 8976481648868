import React, { Component } from "react"

export default class Handswipe extends Component {
  render() {
    return (
      <div>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="100px"
          height="120px"
          viewBox="0 0 100 120"
          enable-background="new 0 0 100 120"
          xmlSpace="preserve"
        >
          <g style={{ fill: "white" }}>
            <path
              fill="white"
              d="M87.153,53.932c-0.827-2.276-3.205-3.806-5.916-3.806c-0.776,0-1.525,0.129-2.227,0.384
                        c-0.651,0.237-1.443,0.708-2.192,1.295c-1.125-2.481-3.624-4.114-6.377-4.114c-0.816,0-1.621,0.143-2.394,0.423
                        c-1.573,0.573-2.82,1.663-3.617,3.012c-1.307-1.546-3.256-2.493-5.353-2.493c-0.815,0-1.621,0.143-2.394,0.423
                        c-1.645,0.599-3.153,1.719-4.16,3.188l-6.182-16.981c-1.192-3.278-3.592-5.234-6.42-5.234c-0.748,0-1.513,0.14-2.272,0.417
                        c-3.683,1.34-5.276,5.276-3.789,9.364L48.397,79.47l-7.479-3.71c-1.103-0.515-2.271-0.775-3.471-0.775
                        c-3.145,0-6.04,1.854-7.378,4.724c-0.927,1.987-1.027,4.215-0.282,6.274c0.745,2.063,2.25,3.71,4.163,4.6
                        c0.068,0.036,6.96,3.719,16.5,8.166L52,99.475c6.116,2.873,11.893,5.586,18.589,5.586h0.001c3.188,0,6.38-0.598,9.758-1.827
                        c11.867-4.319,13.67-11.721,14.5-18.931C95.646,77.367,87.501,54.885,87.153,53.932z M91.867,83.96
                        c-0.781,6.788-2.265,12.712-12.545,16.454c-3.044,1.107-5.9,1.646-8.732,1.646h-0.001c-6.026,0-11.257-2.457-17.313-5.301
                        l-1.558-0.73c-9.459-4.41-16.284-8.057-16.426-8.129c-1.258-0.588-2.211-1.631-2.684-2.938c-0.473-1.309-0.409-2.725,0.18-3.987
                        c0.86-1.845,2.646-2.991,4.659-2.991c0.759,0,1.5,0.166,2.169,0.479l10.817,5.365c0.55,0.273,1.21,0.185,1.668-0.227
                        c0.457-0.41,0.619-1.057,0.407-1.633l-15.83-43.187c-0.709-1.951-0.612-4.566,1.997-5.516c0.431-0.157,0.851-0.236,1.247-0.236
                        c1.513,0,2.858,1.219,3.602,3.26l9.577,26.311c0.284,0.779,1.147,1.18,1.922,0.896c0.779-0.283,1.181-1.145,0.896-1.923
                        l-1.368-3.759c-1.129-3.102,1.271-5.251,3.159-5.938c0.442-0.161,0.902-0.243,1.367-0.243c1.676,0,3.188,1.058,3.761,2.633
                        l1.864,5.125l0.017,0.044c0.284,0.779,1.147,1.18,1.922,0.896c0.778-0.284,1.18-1.144,0.896-1.922l-0.876-2.409
                        c-0.712-2.052,0.369-4.324,2.411-5.067c0.442-0.161,0.902-0.243,1.368-0.243c1.675,0,3.186,1.059,3.76,2.634l2.192,6.024
                        l0.029,0.083c0.283,0.778,1.141,1.181,1.923,0.896c0.778-0.283,1.18-1.145,0.896-1.923l-1.306-3.587
                        c0.463-0.48,1.437-1.248,2.1-1.489c0.371-0.135,0.775-0.204,1.201-0.204c1.432,0,2.705,0.753,3.098,1.832
                        C86.632,61.27,92.463,78.781,91.867,83.96z"
            />
            <path
              fill="white"
              d="M14.746,24.185c17.562-4.042,35.919-3.444,53.245,1.791c1.025,0.309,2.358-0.309,2.672-1.258
                        c0.313-0.95-0.44-2.248-1.515-2.571c-17.995-5.437-37.06-6.057-55.299-1.86c-0.337-1.462-0.673-2.924-1.009-4.386
                        c-0.137-0.59-0.389-1.095-1.083-1.17c-0.693-0.074-1.117,0.163-1.499,0.702c-1.78,2.512-3.427,5.066-4.938,7.649
                        c-0.442,0.758-0.141,1.659,0.646,2.021c2.688,1.24,5.274,2.584,7.751,4.021c0.531,0.308,0.95,0.337,1.453-0.021
                        c0.502-0.358,0.496-0.916,0.36-1.507C15.27,26.458,15.008,25.322,14.746,24.185z"
            />
          </g>
        </svg>
      </div>
    )
  }
}
