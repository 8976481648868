import React, { useRef, useEffect } from "react"
import Details from "./Details"
import gsap from "gsap"
import TitlesBanner from "./TitlesBanner"
import { Trans } from "@lingui/macro"
import { connect } from "react-redux"

const NewBanner = ({ roomPageAnimation3sDelay }) => {
  let detailsDiv = useRef(null)
  let titles = useRef(null)

  useEffect(() => {
    const windowHeight = window.innerWidth
    let heightsProportion = 0.025

    gsap.set(titles, { y: -1000 })
    let tl = gsap.timeline()
    tl.set(detailsDiv, { y: -100 })
      .to(titles, { y: -100, delay: 2.5 })
      .to([detailsDiv, titles], {
        y: windowHeight * heightsProportion,
      })
  }, [])
  return (
    <>
      <div className="new-banner-room">
        <div
          style={{ position: "relative", zIndex: 100 }}
          ref={el => (titles = el)}
        >
          <TitlesBanner />
        </div>
        <div
          style={{ position: "relative", zIndex: 1 }}
          ref={el => (detailsDiv = el)}
        >
          <Details />
        </div>
      </div>
      <div
        className="title-section-roomspages offset-1"
        style={{
          paddingTop: "15%",
          fontSize: "calc(12px + 0.4vw",
          textTransform: "uppercase",
        }}
      >
        <Trans>NOS DIFFÉRENCES</Trans>
      </div>
    </>
  )
}

const mapStateToProps = ({ roomPageAnimation3sDelay }) => {
  return { roomPageAnimation3sDelay }
}

export default connect(mapStateToProps, null)(NewBanner)
