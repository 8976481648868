import React, { useState } from "react"
import { Image } from "../gatsby-images/image"
import AwesomeSlider from "react-awesome-slider"
import AwesomeSliderStyles from "react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss"
import { Trans } from "@lingui/macro"
import { connect } from "react-redux"
import Handswipe from "./Handswipe"

const RightArrow = () => {
  return (
    <svg
      width="36"
      height="36"
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
      style={{ fill: "aliceblue", transform: "scale(1.5)", paddingTop: 12 }}
    >
      <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-3 5.753l6.44 5.247-6.44 5.263.678.737 7.322-6-7.335-6-.665.753z" />
    </svg>
  )
}

const LeftArrow = () => {
  return (
    <svg
      width="36"
      height="36"
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
      style={{ fill: "aliceblue", transform: "scale(1.5) rotate(180deg)" }}
    >
      <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-3 5.753l6.44 5.247-6.44 5.263.678.737 7.322-6-7.335-6-.665.753z" />
    </svg>
  )
}

const ShowModal = ({ photos, updateModalPhotos, updateShowModal }) => {
  const showModalFunc = () => {
    updateModalPhotos(photos)
    updateShowModal(true)
  }
  return (
    <div className="show-modal" onClick={() => showModalFunc()}>
      <svg
        fill="white"
        width="48"
        height="48"
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
      >
        <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5zm-4.5 8h4v-4h1v4h4v1h-4v4h-1v-4h-4v-1z" />
      </svg>
    </div>
  )
}

const RoomCardComponent = ({
  photos,
  title,
  description,
  subtitle,
  view,
  sdb,
  lit,
  superficie,
  occupation,
  updateShowReservationCenter,
  isTouchScreen,
  updateModalPhotos,
  updateShowModal,
}) => {
  const [showHandSwiper, setShowHandswiper] = useState(true)
  const [totalSlides, setTotalSlides] = useState(1)

  return (
    <div className="room-section">
      <div className="room-card">
        <div className="image-slider">
          <AwesomeSlider
            organicArrows={false}
            animation="scaleOutAnimation"
            cssModule={AwesomeSliderStyles}
            mobileTouch={true}
            // organicArrows={true}
            buttonContentLeft={<LeftArrow />}
            buttonContentRight={<RightArrow />}
            // onTransitionEnd={(e) => setSlideNumber(e.currentIndex)}
            onFirstMount={e => {
              setTotalSlides(e.slides)
            }}
            bullets={false}
          >
            {photos.map(photo => {
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                  onTouchStart={() => setShowHandswiper(false)}
                >
                  <ShowModal
                    photos={photos}
                    updateModalPhotos={updateModalPhotos}
                    updateShowModal={updateShowModal}
                  />
                  <Image src={photo} />
                  {/* <div style={{position: 'absolute', bottom: '30px', left: 'calc(50% - 50px)'}}>
                                            { isTouchScreen && showHandSwiper && <Handswipe /> }
                                        </div> */}
                </div>
              )
            })}
          </AwesomeSlider>
        </div>
        <div className="text">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="room-title">{title}</div>
            {subtitle && <div style={{ marginLeft: 10 }}> - {subtitle}</div>}
          </div>
          <ul className="amenities">
            {view && <li>{view}</li>}
            {superficie && <li>{superficie}</li>}
            {occupation && <li>{occupation}</li>}
            {
              <li>
                <Trans>Climatisation réversible</Trans>
              </li>
            }
            {sdb && <li>{sdb}</li>}
            {lit && <li>{lit}</li>}
            {
              <li>
                <Trans>nespresso</Trans>
              </li>
            }
          </ul>
          <div className="room-description">{description}</div>
        </div>
        <div
          onClick={() => updateShowReservationCenter()}
          className="check-availability"
        >
          <Trans>Vérifier la disponibilité</Trans>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ language, isTouchScreen }) => {
  return { language, isTouchScreen }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowReservationCenter: () =>
      dispatch({
        type: `SHOW_RESERVATION_CENTER`,
      }),
    updateModalPhotos: arr =>
      dispatch({
        type: `SET_PHOTOS_MODAL`,
        payload: arr,
      }),
    updateShowModal: bool =>
      dispatch({
        type: `UPDATE_OPEN_MODAL`,
        payload: bool,
      }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoomCardComponent)
