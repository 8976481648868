import React, { useState } from "react"
import Modal from "react-modal"
import AwesomeSlider from "react-awesome-slider"
import AwesomeSliderStyles from "react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss"
import { Image } from "../gatsby-images/image"
import { Trans } from "@lingui/macro"
import { connect } from "react-redux"
import Handswipe from "./Handswipe"

const RightArrow = () => {
  return (
    <svg
      width="36"
      height="36"
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
      style={{ fill: "aliceblue", transform: "scale(1.5)", paddingTop: 12 }}
    >
      <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-3 5.753l6.44 5.247-6.44 5.263.678.737 7.322-6-7.335-6-.665.753z" />
    </svg>
  )
}

const LeftArrow = () => {
  return (
    <svg
      width="36"
      height="36"
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
      style={{ fill: "aliceblue", transform: "scale(1.5) rotate(180deg)" }}
    >
      <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-3 5.753l6.44 5.247-6.44 5.263.678.737 7.322-6-7.335-6-.665.753z" />
    </svg>
  )
}

const PhotoModal = ({
  isModalOpen,
  isTouchScreen,
  updateShowModal,
  modalPhotos,
}) => {
  const [showHandSwiper, setShowHandswiper] = useState(true)
  const [totalSlides, setTotalSlides] = useState(1)

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        //   onAfterOpen={afterOpenModal}
        onRequestClose={() => updateShowModal(false)}
        //   style={customStyles}
        contentLabel="Example Modal"
      >
        {modalPhotos.length ? (
          <div className="modal-photos">
            <div className="close-modal" onClick={() => updateShowModal(false)}>
              X
            </div>
            <div className="slider-container">
              <AwesomeSlider
                organicArrows={false}
                animation="scaleOutAnimation"
                cssModule={AwesomeSliderStyles}
                mobileTouch={true}
                // organicArrows={true}
                buttonContentLeft={<LeftArrow />}
                buttonContentRight={<RightArrow />}
                // onTransitionEnd={(e) => setSlideNumber(e.currentIndex)}
                onFirstMount={e => {
                  setTotalSlides(e.slides)
                }}
                bullets={false}
              >
                {modalPhotos.map(photo => {
                  return (
                    <div
                      className="slider-slider"
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                      onTouchStart={() => setShowHandswiper(false)}
                    >
                      <Image src={photo} />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "30px",
                          left: "calc(50% - 50px)",
                        }}
                      >
                        {isTouchScreen && showHandSwiper && <Handswipe />}
                      </div>
                    </div>
                  )
                })}
              </AwesomeSlider>
            </div>
          </div>
        ) : (
          <div className="modal-photos">
            <div className="close-modal" onClick={() => updateShowModal(false)}>
              X
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

const mapStateToProps = ({ isModalOpen, isTouchScreen, modalPhotos }) => {
  return { isModalOpen, isTouchScreen, modalPhotos }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowModal: bool =>
      dispatch({
        type: `UPDATE_OPEN_MODAL`,
        payload: bool,
      }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PhotoModal)
