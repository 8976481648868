import React, { useEffect, useRef, useState } from "react"
import { Image } from "../gatsby-images/image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Trans } from "@lingui/macro"
import { connect } from "react-redux"

const Introduction = ({
  scrolltriggerReady,
  windowWidth,
  updateRoomsListColor,
}) => {
  // let firstPhoto = useRef(null)
  let panelsRef = useRef([])
  let textsRef = useRef([])
  let titlesRef = useRef([])
  let fixBox = useRef(null)
  let lastPhoto = useRef(null)
  let imagesRef = useRef(null)
  let wrapper = useRef(null)
  let scrollConst = useRef(null)
  let lastImage = useRef(null)
  let isSafari

  if (typeof window !== `undefined`) {
    isSafari = /apple/i.test(navigator.vendor) ? true : false
  }

  // const revealRefs = useRef([]);
  panelsRef.current = []
  textsRef.current = []
  titlesRef.current = []
  imagesRef.current = []

  //tweens
  let imgsRefAnimation = useRef(null)
  imgsRefAnimation.current = []

  const addToImgToRefAnimation = el => {
    if (el && !imgsRefAnimation.current.includes(el)) {
      imgsRefAnimation.current.push(el)
    }
  }

  let fixboxTextsAnimation = useRef(null)
  fixboxTextsAnimation.current = []
  const addToFixboxTextsAnimation = el => {
    if (el && !fixboxTextsAnimation.current.includes(el)) {
      fixboxTextsAnimation.current.push(el)
    }
  }

  const addToPanelsRef = el => {
    if (el && !panelsRef.current.includes(el)) {
      panelsRef.current.push(el)
    }
  }

  const addToTitlesRef = el => {
    if (el && !titlesRef.current.includes(el)) {
      titlesRef.current.push(el)
    }
  }

  const addToTextsRef = el => {
    if (el && !textsRef.current.includes(el)) {
      textsRef.current.push(el)
    }
  }

  const addToImagesRef = el => {
    if (el && !panelsRef.current.includes(el)) {
      imagesRef.current.push(el)
    }
  }

  const imagesAnimations = useRef([])

  const killAnimations = () => {
    imgsRefAnimation.current &&
      imgsRefAnimation.current.map(anim => anim.kill())
    scrollConst.current && scrollConst.current.kill()
    const scrollTriggersToKill = imagesRef.current.map((num, index) => {
      return ScrollTrigger.getById(`rooms-introuction-text-${index}`)
    })
    scrollTriggersToKill.map(st => st && st.kill())
  }

  const [resetAnimations, setResetAnimations] = useState(false)

  useEffect(() => {
    let screenWidth = window.innerWidth
    let wrapperHeight = wrapper.getBoundingClientRect().height * 0.7

    if (scrolltriggerReady) {
      killAnimations()
      if (screenWidth < 576) {
        gsap.set(fixBox, { yPercent: -75 })
      }
      var newImages = imagesRef.current
      let wrapperH = `${wrapper.getBoundingClientRect().height}`

      fixboxTextsAnimation.current = gsap.timeline({
        scrollTrigger: {
          trigger: fixBox,
          pin: fixBox,
          start: "top 30%",
          //   endTrigger: lastPhoto,
          end: screenWidth < 576 ? `+=${wrapperH}` : `${wrapperH} 80%`,
          toggleActions: "play none none reset",
        },
      })

      var changeText = panelsRef.current
      var newText = textsRef.current
      var newTitle = titlesRef.current

      let imageWrapper = newImages.map(img => img.children[0])
      let image = newImages.map(img => img.children[0].children[0])
      const windowHeight = window.innerHeight
      imageWrapper.forEach(function (changeText, index) {
        gsap.set(image[index], { scale: 1.1 })
        const animation = gsap.to(image[index], {
          yPercent: -25,
          ease: "none",
          scrollTrigger: {
            trigger: imageWrapper[index],
            start: screenWidth < 576 ? "top 100%" : "top 100%",
            end: windowHeight ? `${windowHeight}px` : "bottom top",
            scrub: true,
          },
        })
        addToImgToRefAnimation(animation)
        const scroll = ScrollTrigger.create({
          id: `rooms-introuction-text-${index}`,
          trigger: changeText,
          start: screenWidth < 576 ? "top 95%" : "top 60%",
          end: screenWidth < 576 ? "bottom 55%" : "bottom 30%",
          scrub: true,
          onEnter: function () {
            gsap.to([newText[index], newTitle[index]], {
              css: { visibility: "visible", autoAlpha: 1, left: 0 },
              duration: 0.1,
            })
          },
          onEnterBack: function () {
            gsap.to([newText[index], newTitle[index]], {
              css: { visibility: "visible", autoAlpha: 1, left: 0 },
              duration: 0.1,
            })
          },
          onLeave: function () {
            if (index != 3) {
              gsap.to([newText[index], newTitle[index]], {
                css: { visibility: "hidden", left: 0 },
                duration: 0.1,
              })
            }
          },
          onLeaveBack: function () {
            if (index != 0) {
              gsap.to([newText[index], newTitle[index]], {
                css: { visibility: "hidden", left: 0 },
                duration: 0.1,
              })
            }
          },
        })
      })
    }
  }, [scrolltriggerReady, windowWidth])

  useEffect(() => {
    return () => {
      killAnimations()
    }
  }, [])

  return (
    <div className="spa-container rooms-page">
      <div className="photos-container rooms" ref={el => (wrapper = el)}>
        <div className="fix-box  rooms" ref={el => (fixBox = el)}>
          <div className="box-title">
            <div className="titles rooms-page">
              <div>
                <p
                  ref={el => addToTitlesRef(el)}
                  style={{ visibility: "visible" }}
                >
                  <Trans>Imprenables</Trans>
                </p>
              </div>
              <p
                ref={el => addToTitlesRef(el)}
                style={{ visibility: "hidden" }}
              >
                <Trans>Luxueux</Trans>
              </p>
              <p
                ref={el => addToTitlesRef(el)}
                style={{ visibility: "hidden" }}
              >
                <Trans>Privé</Trans>
              </p>
              <p
                ref={el => addToTitlesRef(el)}
                style={{ visibility: "hidden" }}
              >
                <Trans>Confort</Trans>
              </p>
              <div className="small-screen-separation"></div>
            </div>
          </div>
          <div className="text d-none d-sm-block">
            <div className="text-layer">
              <div
                id="textRef-0"
                ref={el => addToTextsRef(el)}
                style={{ visibility: "visible" }}
              >
                <div
                  className="line-separation"
                  style={{ visibility: "visible" }}
                ></div>
                <p>
                  <strong>1 </strong>/ 4
                </p>
              </div>
              <div id="textRef-1" ref={el => addToTextsRef(el)}>
                <div className="line-separation"></div>
                {/* <div className="counter-fix-box">
                                    <p className='counter'><strong>2</strong></p>
                                    <p className='total'>/ 4</p>
                                </div> */}
                <p>
                  <strong>2 </strong> / 4
                </p>
              </div>
              <div id="textRef-2" ref={el => addToTextsRef(el)}>
                <div className="line-separation"></div>
                <p>
                  <strong>3 </strong> / 4
                </p>
              </div>
              <div id="textRef-3" ref={el => addToTextsRef(el)}>
                <div className="line-separation"></div>
                <p>
                  <strong>4 </strong> / 4
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9 offset-sm-3 col-xs-12">
            <div
              className="photo small-screen new pannel"
              ref={el => addToPanelsRef(el)}
            >
              <div className="image room-page " ref={el => addToImagesRef(el)}>
                <div
                  className="overflow-div"
                  style={{ overflow: isSafari ? "" : "hidden" }}
                >
                  <Image src="view-chateau-rooms" />
                </div>
                <div className="description-box">
                  <div className="title-box">
                    <Trans>Vues Imprenables</Trans>
                  </div>
                  <div className="line-separation"></div>
                  <div className="text-box">
                    <Trans>
                      Offrez-vous des vues pittoresques sur le Château, notre
                      jardin paysager avec sa piscine et ses toits Amboisiens
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          //  ref={el => firstPhoto = el}
        >
          <div className="col-sm-9 offset-sm-3">
            <div
              className="photo  small-screen new pannel"
              ref={el => addToPanelsRef(el)}
            >
              <div className="image" ref={el => addToImagesRef(el)}>
                <div
                  className="overflow-div"
                  style={{ overflow: isSafari ? "" : "hidden" }}
                >
                  <Image src="bathroom-rooms" />
                </div>
                <div className="description-box pair">
                  <div>
                    <div className="title-box" style={{ marginBottom: 0 }}>
                      <Trans>Douche à Jets et</Trans>
                    </div>
                    <div className="title-box" style={{ marginTop: 0 }}>
                      <Trans>Baignoires Séparées</Trans>
                    </div>
                  </div>
                  <div className="line-separation"></div>
                  <div className="text-box">
                    <Trans>
                      Exclusives, spacieuses et incroyablement bien équipées
                      (baignoires et douches à jets hydromassants séparées),
                      elles sont aussi accueillantes et agréables que les
                      grandes serviettes moelleuses que vous y trouverez.
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          //  ref={el => firstPhoto = el}
        >
          <div className="col-sm-9 offset-sm-3">
            <div
              className="photo  small-screen new pannel"
              ref={el => addToPanelsRef(el)}
            >
              <div className="image" ref={el => addToImagesRef(el)}>
                <div
                  className="overflow-div"
                  style={{ overflow: isSafari ? "" : "hidden" }}
                >
                  <Image src="parasol-facade" />
                </div>
                <div className="description-box">
                  <div>
                    <div className="title-box" style={{ marginBottom: 0 }}>
                      <Trans>Terrasses Privatives</Trans>
                    </div>
                  </div>
                  <div className="line-separation"></div>
                  <div className="text-box">
                    <Trans>
                      D’un petit déjeuner sur votre terrasse à un dernier verre
                      au bar privé qu’offre votre chambre, tout est fait pour
                      que vous vous sentiez comme chez vous.
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" ref={el => (lastPhoto = el)}>
          <div className="col-sm-9 offset-sm-3" ref={el => (lastImage = el)}>
            <div
              className="photo  small-screen new pannel"
              ref={el => addToPanelsRef(el)}
            >
              <div className="image" ref={el => addToImagesRef(el)}>
                <div
                  className="overflow-div"
                  style={{ overflow: isSafari ? "" : "hidden" }}
                >
                  <Image src="nespresso-rooms" />
                </div>
                <div className="description-box pair">
                  <div>
                    <div className="title-box" style={{ marginBottom: 0 }}>
                      <Trans>Grand Confort</Trans>
                    </div>
                    <div className="title-box" style={{ marginTop: 0 }}></div>
                  </div>
                  <div className="line-separation"></div>
                  <div className="text-box">
                    <Trans>
                      Nos chambres disposent d’un confort complet (Nespresso,
                      produits de toilettes Fragonard, peignoirs, chaussons...),
                      pour faire de votre séjour à Amboise, un moment réussi,
                      placé sous le signe de la quiétude et du charme.
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = ({ scrolltriggerReady, windowWidth }) => {
  return { scrolltriggerReady, windowWidth }
}

const mapDispatchToProps = dispatch => {
  return {
    updateRoomsListColor: color =>
      dispatch({ type: "UPDATE_ROOM_LIST_COLOR", payload: color }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Introduction)
