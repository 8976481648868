import React, { useRef, useEffect } from "react"
import { Trans } from "@lingui/macro"

const TitlesBanner = () => {
  useEffect(() => {}, [])
  return (
    <div className="titles-banner text-center">
      <div>
        <div className="logo"></div>
        <div className="main-title">
          <Trans>CHAMBRES</Trans>
        </div>
      </div>
      <div>
        <div className="line">
          <div className="title">
            <Trans>LUXE</Trans>
          </div>
          <div className="last-word">
            <Trans>et</Trans>
          </div>
        </div>
        <div className="line">
          <div className="title">
            <Trans>SOUCIS DU DÉTAIL</Trans>
          </div>
        </div>
        <div className="line">
          <div className="last-word">
            <Trans>pour</Trans>
          </div>
          <div className="title">
            <Trans>UN SÉJOUR AUTHENTIC</Trans>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TitlesBanner
