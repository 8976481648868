import React, { useRef, useEffect, useState } from "react"
import gsap from "gsap"
import RoomListComponent from "./RoomsListCoomponent"
import RoomsListData from "./rooms-list-data.json"
import { connect } from "react-redux"
import Scrollbar from "smooth-scrollbar"
import { Trans } from "@lingui/macro"

const textFixBox = [
  {
    title: { fr: "Sur Place", en: "Inside the Hotel", ru: "на месте" },
    mainTitle: {
      fr: "Côté ville",
      en: "City side rooms",
      ru: "Номера с видом на город",
    },
    listActivities: {
      fr: [
        {
          title: "Confort",
          src: "confort-room",
        },
        {
          title: "Confort Supérieur",
          src: "confort-superior-room",
        },
        {
          title: "Privilège",
          src: "privilege-room",
        },
      ],
      en: [
        {
          title: "Comfort",
          src: "confort-room",
        },
        {
          title: "Superior Comfort",
          src: "confort-superior-room",
        },
        {
          title: "Privilege",
          src: "privilege-room",
        },
      ],
      ru: [
        {
          title: "Конфор",
          src: "confort-room",
        },
        {
          title: "Конфор Сюперьор",
          src: "confort-superior-room",
        },
        {
          title: "Привилеж",
          src: "privilege-room",
        },
      ],
    },
  },
  {
    title: { fr: "", en: "" },
    mainTitle: {
      fr: "Vue Château",
      en: "Castle views",
      ru: "Номера с видом на замок",
    },
    listActivities: {
      fr: [
        {
          title: "Prestige Bleue",
          src: "prestige-canard-room",
        },
        {
          title: "Prestige Mauve",
          src: "prestige-mauve-room",
        },
        {
          title: "Prestige Anis",
          src: "prestige-anis-room",
        },
        {
          title: "Exécutive",
          src: "executive-room",
        },
      ],
      en: [
        {
          title: "Bleue Canard Prestige",
          src: "prestige-canard-room",
        },
        {
          title: "Mauve Prestige",
          src: "prestige-mauve-room",
        },
        {
          title: "Anis Prestige",
          src: "prestige-anis-room",
        },
        {
          title: "Executive",
          src: "executive-room",
        },
      ],
      ru: [
        {
          title: "Престиж Блё Канар",
          src: "prestige-canard-room",
        },
        {
          title: "Престиж Рум Мов",
          src: "prestige-mauve-room",
        },
        {
          title: "Престиж Рум Анис",
          src: "prestige-anis-room",
        },
        {
          title: "Экзекьютив",
          src: "executive-room",
        },
      ],
    },
  },
  {
    title: { fr: "Aux Alentours", en: "Arround the Hotel" },
    mainTitle: {
      fr: "Avec Terrasse",
      en: "With Terrace",
      ru: "Номера с терассами",
    },
    listActivities: {
      fr: [
        {
          title: "Prestige Terrasse",
          src: "prestige-terrasse-room",
        },
        {
          title: "Confort Jardin",
          src: "garden-comfort-room",
        },
        {
          title: "Prestige Jardin",
          src: "garden-prestige-room",
        },
      ],
      en: [
        {
          title: "Prestige Terrasse",
          src: "prestige-terrasse-room",
        },
        {
          title: "Comfort Garden",
          src: "garden-comfort-room",
        },
        {
          title: "Prestige Garden",
          src: "garden-prestige-room",
        },
      ],
      ru: [
        {
          title: "Престиж Террас",
          src: "prestige-terrasse-room",
        },
        {
          title: "Конфор Жарден",
          src: "garden-comfort-room",
        },
        {
          title: "Престиж Жарден",
          src: "garden-prestige-room",
        },
      ],
    },
  },
]

const RoomsList = ({
  setBackgroundColor,
  backgroundColor,
  language,
  activityTitle,
  scrolltriggerReady,
  roomListCurrentTitle,
  roomListColor,
  windowWidth,
  isFixboxVisible,
}) => {
  let textArr = useRef([])
  let listComponentsContainer = useRef([])
  let roomsListContainer = useRef(null)
  let sectionTitle = useRef(null)
  let fixBox = useRef(null)

  //tweens
  let setFixBoxTween = useRef(null)
  let fixBixTween = useRef(null)
  let changeTitleColorTween = useRef(null)
  let changeFixboxOpacityTween = useRef(null)

  const addToListComponentsContainer = el => {
    if (el && !listComponentsContainer.current.includes(el)) {
      listComponentsContainer.current.push(el)
    }
  }

  const [scrollTo, setScrollTo] = useState(null)

  useEffect(() => {
    // onclick useScrollBar and head to specific element
    if (scrollTo) {
      const scrollbar = Scrollbar.get(document.getElementById("scroller-app"))
      scrollbar.scrollIntoView(document.getElementById(`${scrollTo}`))
    }
  }, [scrollTo])

  const createMyTweens = () => {
    const fixBoxConst = fixBox
    const sectionTitleConst = sectionTitle

    setFixBoxTween.current = gsap.set(fixBoxConst, { yPercent: 10 })
    fixBixTween.current = gsap.timeline({
      scrollTrigger: {
        trigger: listComponentsContainer[0],
        pin: fixBoxConst,
        start: "50% 50%",
        endTrigger: roomsListContainer,
        end: "90% 10%",
        toggleActions: "play none none reset",
      },
    })

    changeTitleColorTween.current = gsap.to(sectionTitleConst, {
      scrollTrigger: {
        trigger: roomsListContainer,
        start: "top 50%",
        //events: onEnter onLeave onEnterBack onLeaveBack
        toggleActions: "play none none reset",
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
      color: "white",
    })

    // changeFixboxOpacityTween.current = gsap.fromTo(fixBox, {visibility: 'hidden'},{
    //   scrollTrigger: {
    //     trigger:roomsListContainer,
    //     start: "top 10%",
    //     //events: onEnter onLeave onEnterBack onLeaveBack
    //     toggleActions:"play none none reset"
    //     //options: play, pause, resume, reset, restart, complete, reverse,none
    //   },
    //   visibility: 'visible',
    //   duration: 1
    // })
  }

  useEffect(() => {
    const componentsContainer = listComponentsContainer.current
    const textArrMove = textArr.current
    const numBoxes = textArrMove.length

    if (scrolltriggerReady) {
      //fixBox
      const fixBoxConst = fixBox
      const roomsListContainerConst = roomsListContainer
      // const outsideActivitiesConst = outsideActivities
      createMyTweens()
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    gsap.to(fixBox, {
      backgroundColor: roomListColor,
    })
  }, [roomListColor])

  useEffect(() => {
    return () => {
      fixBixTween.current && fixBixTween.current.kill()
      changeTitleColorTween.current && changeTitleColorTween.current.kill()
      changeFixboxOpacityTween.current &&
        changeFixboxOpacityTween.current.kill()
    }
  }, [])

  useEffect(() => {
    // changeTitleColorTween.current && changeTitleColorTween.current.kill()
    fixBixTween.current && fixBixTween.current.kill()
    changeTitleColorTween.current && changeTitleColorTween.current.kill()
    changeFixboxOpacityTween.current && changeFixboxOpacityTween.current.kill()
  }, [windowWidth])

  return (
    <div className="rooms-list" ref={el => (roomsListContainer = el)}>
      <div className="row">
        <div
          className="main-title offset-1"
          style={{ fontSize: "calc(12px + 0.4vw", textTransform: "uppercase" }}
          ref={el => (sectionTitle = el)}
        >
          <Trans>Nos Chambres</Trans>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8">
          {RoomsListData.map(json => {
            return (
              <div
                key={json.id}
                ref={el => addToListComponentsContainer(el)}
                data-color={json[language].backgroundColor}
                style={{ height: windowWidth < 400 ? "100vh" : null }}
              >
                <RoomListComponent
                  id={json[language].id}
                  src={json[language].src}
                  leftPosition={json[language].leftPosition}
                  key={json[language].id}
                  title={json[language].title}
                  setBackgroundColor={setBackgroundColor}
                  backgroundColor={backgroundColor}
                  color={json[language].backgroundColor}
                  bedSize={json[language].bedSize}
                  maxOccupation={json[language].maxOccupation}
                  area={json[language].area}
                  hotelLocation={json[language].hotelLocation}
                  view={json[language].view}
                  sdb={json[language].sdb}
                  terrace={json[language].terrace}
                />
              </div>
            )
          })}
        </div>
        <div
          className="d-none d-sm-block col-sm-4"
          style={{ position: "relative" }}
        >
          <div
            className="fix-box-rooms-list"
            ref={el => (fixBox = el)}
            style={{ zIndex: 100, visibility: isFixboxVisible }}
          >
            <div>
              {textFixBox.map((box, i) => {
                return (
                  <div
                    className="inside-box"
                    key={`${box.title[language]}-box-numebr-activities-${i}`}
                  >
                    <div className="title-div">
                      <div className="mainTitle">{box.mainTitle[language]}</div>
                      <div className="separation"></div>
                      {/* <div className="title">
                                                    {box.title[language]}
                                                </div> */}
                    </div>
                    <div className="activities-list">
                      {box.listActivities[language].map((activity, i) => {
                        return (
                          // <AnchorLink
                          //     to={`/activities#${activity.src}`}
                          //     key={`${activity.title}-anchor`}

                          // >
                          <div
                            className="room-type"
                            // onClick={() => setScrollTo(activity.src)}
                            // id={`${box.title}-activities-list-${i}`}
                            onClick={() => setScrollTo(activity.src)}
                            key={`${box.title}-activities-list-${i}`}
                            style={
                              activityTitle === activity.title
                                ? { opacity: "1" }
                                : null
                            }
                          >
                            {roomListCurrentTitle === activity.src && (
                              <div className="separation"></div>
                            )}
                            <div className="title">{activity.title}</div>
                          </div>
                          // </AnchorLink>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  language,
  activityTitle,
  scrolltriggerReady,
  roomListCurrentTitle,
  roomListColor,
  windowWidth,
  isFixboxVisible,
}) => {
  return {
    language,
    activityTitle,
    scrolltriggerReady,
    roomListCurrentTitle,
    roomListColor,
    windowWidth,
    isFixboxVisible,
  }
}

export default connect(mapStateToProps, null)(RoomsList)
