import React, { useRef, useEffect, useState } from "react"
import gsap from "gsap"
import { Image } from "../gatsby-images/image"
import useMousePosition from "../../hooks/useMousePosition"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { connect } from "react-redux"
import TransitionLink from "gatsby-plugin-transition-link"
import { Trans } from "@lingui/macro"

const RoomsListCoomponent = ({
  src,
  leftPosition,
  title,
  setBackgroundColor,
  color,
  bedSize,
  area,
  hotelLocation,
  view,
  sdb,
  terrace,
  maxOccupation,
  id,
  isTouchScreen,
  showTransitionBanner,
  hideTransitionBanner,
  resetOffset,
  scrolltriggerReady,
  updateRoomsListTitle,
  updateRoomsListColor,
  isRoomsPage,
  windowWidth,
  roomListColor,
  updateVisibility,
  language,
  isFromRoom,
}) => {
  let textArr = useRef([])
  let textContainer = useRef(null)
  let image = useRef(null)
  let cursorImage = useRef(null)
  let mainContainer = useRef()
  let descriptionBox = useRef(null)
  let infosText = useRef()
  let overflowDiv = useRef()
  let isSafari

  const [titleColor, setTitleColor] = useState("black")

  //tweens
  let imageMoveTween = useRef(null)
  let textsMoveTween = useRef(null)
  let changeBackgroundColorTween = useRef(null)
  let setDescriptionBoxTween = useRef(null)
  let setImageWrapperTween = useRef(null)
  let setInfosTextTween = useRef(null)
  let animateInfosTextTween = useRef(null)
  let roomsCityTitle = useRef(null)

  if (typeof window !== `undefined`) {
    isSafari = /apple/i.test(navigator.vendor) ? true : false
  }

  const [minHeight, setMinHeight] = useState({ updated: false, height: 0 })

  const { x, y } = useMousePosition()
  let imageX
  let imageY
  const addToTextArr = el => {
    if (el && !textArr.current.includes(el)) {
      textArr.current.push(el)
    }
  }

  //zoom on hover
  const [imageZoom, setImageZoom] = useState(false)

  let zoomIn = l => {
    showCursor()
    gsap.to(image, { scale: 1.1 })
    setImageZoom(true)
  }

  let zoomOut = () => {
    zoomIn && gsap.to(image, { scale: 1 })
  }

  //cursor image view

  let showCursor = () => {
    gsap.to(cursorImage, { autoAlpha: 1 })
  }

  let hideCursor = () => {
    gsap.to(cursorImage, { autoAlpha: 0 })
  }

  let handleCursor = el => {
    imageX = image.getBoundingClientRect().x
    imageY = image.getBoundingClientRect().y
    // console.log(x,'x', y, 'y')
    gsap.to(cursorImage, {
      duration: 0.6,
      ease: "linear",
      x: el.clientX - imageX - 15,
      y: el.clientY - imageY - 20,
    })
  }

  const createMyTweens = () => {
    const descriptionBoxConst = descriptionBox
    const overflowDivConst = overflowDiv
    const mainContainerConst = mainContainer
    const textArrMove = textArr.current
    let imageWrapper = overflowDivConst.children[0]

    setDescriptionBoxTween.current = gsap.set(descriptionBoxConst, {
      opacity: 0,
    })
    setImageWrapperTween.current = gsap.set(imageWrapper, { scale: 1.2 })
    //images move
    const windowHeight = window.innerHeight
    imageMoveTween.current = gsap.to(imageWrapper, {
      yPercent: -25,
      scrollTrigger: {
        trigger: imageWrapper,
        start: "top 100%",
        end: windowHeight ? `${windowHeight}px` : "bottom top",
        scrub: true,
      },
    })

    //moving text
    textsMoveTween.current = textArrMove.forEach(item => {
      let boxWidth = item.getBoundingClientRect().width
      gsap.to(item, {
        x: `-=${boxWidth}`,
        duration: 15,
        repeat: -1,
        ease: "none",
        modifiers: {
          x: gsap.utils.unitize(x => {
            x = parseFloat(x) % -boxWidth
            return x
          }),
        },
      })
    })
    changeBackgroundColorTween.current = ScrollTrigger.create({
      trigger: mainContainerConst,
      start: "top 50%",
      end: "bottom 10%",
      onEnter: () => {
        if (window.location.pathname.includes("rooms")) {
          gsap.to(descriptionBoxConst, { opacity: 1, duration: 2 })
          // setBackgroundColor(color)
          roomListColor != color && updateRoomsListColor(color)
          updateRoomsListTitle(id)
          if (color === "#EBBB75") {
            setTitleColor("white")
            updateVisibility("visible")
          }
        }
      },
      onEnterBack: x => {
        // setBackgroundColor(color)
        if (window.location.pathname.includes("rooms")) {
          gsap.to(descriptionBoxConst, { opacity: 1, duration: 2 })
          updateRoomsListColor(color)
          updateRoomsListTitle(id)
        }
      },
      onLeaveBack: x => {
        window.location.pathname.includes("rooms") &&
          color === "#EBBB75" &&
          updateRoomsListColor("#F2F2F2")
        gsap.to(descriptionBoxConst, { opacity: 0, duration: 2 })
        if (color === "#EBBB75") {
          setTitleColor("black")
          updateVisibility("hidden")
        }
      },
      onLeave: () => {
        gsap.to(descriptionBoxConst, { opacity: 0, duration: 2 })
      },
    })
  }

  useEffect(() => {
    const textArrMove = textArr.current
    const numBoxes = textArrMove.length
    const descriptionBoxConst = descriptionBox
    const overflowDivConst = overflowDiv

    let boxHeight = descriptionBox.getBoundingClientRect().height
    let imageHeight = image.getBoundingClientRect().height
    let minHeight = boxHeight + imageHeight + 50

    let mainContainerHeight = mainContainer.getBoundingClientRect().height

    if (!minHeight.updated && mainContainerHeight < minHeight) {
      setMinHeight({ updated: true, height: minHeight })
    }

    const mainContainerConst = mainContainer
    if (scrolltriggerReady) {
      createMyTweens()
    }
  }, [scrolltriggerReady])

  //touch screen animations
  const createTouchScreenTween = () => {
    setInfosTextTween.current = gsap.set(infosText, { visibility: "visible" })
    animateInfosTextTween.current = gsap.fromTo(
      infosText,
      { scale: 0.5 },
      {
        ease: "power2.out",
        scale: 1,
        duration: 1,
        repeat: -1,
        delay: 1,
        yoyo: true,
      }
    )
  }
  useEffect(() => {
    if (isTouchScreen) {
      createTouchScreenTween()
    }
  }, [isTouchScreen])

  useEffect(() => {
    return () => {
      imageMoveTween.current && imageMoveTween.current.kill()
      textsMoveTween.current && textsMoveTween.current.kill()
      changeBackgroundColorTween.current &&
        changeBackgroundColorTween.current.kill()
      setDescriptionBoxTween.current && setDescriptionBoxTween.current.kill()
      setImageWrapperTween.current && setImageWrapperTween.current.kill()
      setInfosTextTween.current && setInfosTextTween.current.kill()
      animateInfosTextTween.current && animateInfosTextTween.current.kill()
    }
  }, [])

  useEffect(() => {
    imageMoveTween.current && imageMoveTween.current.kill()
    textsMoveTween.current && textsMoveTween.current.kill()
    changeBackgroundColorTween.current &&
      changeBackgroundColorTween.current.kill()
    setDescriptionBoxTween.current && setDescriptionBoxTween.current.kill()
    setImageWrapperTween.current && setImageWrapperTween.current.kill()
    setInfosTextTween.current && setInfosTextTween.current.kill()
    animateInfosTextTween.current && animateInfosTextTween.current.kill()

    createMyTweens()
    if (isTouchScreen) {
      createTouchScreenTween()
    }
  }, [windowWidth])

  return (
    <>
      {id === "prestige-canard-room" && (
        <div
          style={{
            fontSize: "5vw",
            color: "white",
            marginTop: "30vw",
            fontFamily: "Italiana",
            marginBottom: "-5vw",
          }}
          className="text-center"
        >
          <Trans>Chambres avec Vue Château</Trans>
        </div>
      )}
      {id === "prestige-terrasse-room" && (
        <div
          style={{
            fontSize: "5vw",
            color: "white",
            marginTop: "30vw",
            fontFamily: "Italiana",
            marginBottom: "-5vw",
          }}
          className="text-center"
        >
          <Trans>Chambres avec Terrasse</Trans>
        </div>
      )}
      {id === "confort-room" && (
        <div
          style={{
            fontSize: "5vw",
            color: titleColor,
            marginTop: "30vw",
            fontFamily: "Italiana",
            marginBottom: "-5vw",
          }}
          className="text-center"
          ref={el => (roomsCityTitle = el)}
        >
          <Trans>Chambres cotées Ville</Trans>
        </div>
      )}
      <div
        style={{ width: "100%" }}
        onClick={() => {
          isFromRoom(true)
          showTransitionBanner()
        }}
        id={id}
      >
        <TransitionLink
          to={`/${language}/rooms/${id}`}
          exit={{
            delay: 2,
          }}
          entry={{
            trigger: ({ node, e, exit, entry }) => {
              hideTransitionBanner()
              resetOffset(true)
            },
          }}
        >
          <div
            className=""
            style={
              // minHeight.udpated?
              { position: "relative" }
              // :
              // {position: 'relative'}
            }
            onMouseLeave={() => zoomOut()}
            ref={el => (mainContainer = el)}
          >
            <div className="rooms-list-container ">
              <div className="room-section" style={{ left: leftPosition }}>
                <div
                  style={{ overflow: "", marginTop: "30vw" }}
                  onMouseEnter={el => zoomIn()}
                  onMouseLeave={() => hideCursor()}
                  onMouseMove={el => handleCursor(el)}
                >
                  <div
                    ref={el => (image = el)}
                    style={{ position: "relative" }}
                  >
                    <div
                      className="more-infos-text"
                      ref={el => (infosText = el)}
                    >
                      <Trans>Découvrir</Trans>
                    </div>
                    <div
                      className="cursor-rooms-view"
                      ref={el => (cursorImage = el)}
                    >
                      <Trans>Découvrir la chambre</Trans>
                      <span style={{ marginLeft: 5 }}>{title}</span>
                    </div>
                    <div
                      className="overflow-div-roomslist"
                      //overflow not working properly on safari
                      style={{
                        overflow: isSafari && !isTouchScreen ? "" : "hidden",
                      }}
                      ref={el => (overflowDiv = el)}
                    >
                      <Image src={src} />
                    </div>
                  </div>
                  <div
                    className="description-box"
                    ref={el => (descriptionBox = el)}
                  >
                    <div>
                      <div className="title">
                        <Trans>Chambre</Trans>
                        <span style={{ marginLeft: 5 }}>{title}</span>
                      </div>
                      <div className="separation-line"></div>
                    </div>
                    <div className="list-attributes">
                      <ul>
                        {view && (
                          <li>
                            <Trans>Vue :</Trans> <span>{view}</span>
                          </li>
                        )}
                        {terrace && <li>{`${terrace}`}</li>}
                        {area && (
                          <li>
                            <Trans>Superficie :</Trans> <span>{area}</span>
                            <span>m</span>
                            <sup>2</sup>
                          </li>
                        )}
                        {sdb && (
                          <li>
                            <Trans>Sdb :</Trans> <span>{sdb}</span>
                          </li>
                        )}
                        {maxOccupation && (
                          <li>
                            <Trans>Occupation :</Trans>{" "}
                            <span>{maxOccupation}</span>
                          </li>
                        )}
                        {bedSize && (
                          <li>
                            <Trans>Lits :</Trans> <span>{bedSize}</span>
                          </li>
                        )}
                        {hotelLocation && (
                          <li>
                            <Trans>Situation dans la :</Trans>{" "}
                            <span> {hotelLocation}</span>
                          </li>
                        )}
                        <li>
                          <Trans>Climatisation réversible</Trans>
                        </li>
                        {
                          <li>
                            <Trans>WC séparé et fermé</Trans>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                  {/* <div className='title-photo'>{`Chambre ${title}`}</div> */}
                </div>
              </div>
              <div className="moving-text" ref={el => (textContainer = el)}>
                <div ref={el => addToTextArr(el)}>{title} </div>
                <div ref={el => addToTextArr(el)}>{title} </div>
              </div>
            </div>
          </div>
        </TransitionLink>
      </div>
    </>
  )
}

const mapStateToProps = ({
  isTouchScreen,
  scrolltriggerReady,
  windowWidth,
  roomListColor,
  language,
}) => {
  return {
    isTouchScreen,
    scrolltriggerReady,
    windowWidth,
    roomListColor,
    language,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
    updateRoomsListTitle: title =>
      dispatch({ type: "UPDATE_ROOM_LIST_TITLE", payload: title }),
    updateRoomsListColor: color =>
      dispatch({ type: "UPDATE_ROOM_LIST_COLOR", payload: color }),
    updateVisibility: visibility => {
      dispatch({ type: "UPDATE_FIXBOX_VISIBILITY", payload: visibility })
    },
    isFromRoom: bool => dispatch({ type: "IS_FROM_ROOM", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsListCoomponent)
