import React, { useState, useEffect } from "react"
import { Trans } from "@lingui/macro"
import { console } from "globalthis/implementation"
import RoomCardComponent from "./RoomCardComponent"
import roomsData from "./rooms-data.json"
import { connect } from "react-redux"
import PhotoModal from "./PhotoModal"

const NewRoomComponent = ({ language }) => {
  console.log(
    roomsData
      .filter(obj => obj[language])[0]
      [language].filter(obj => obj["type"] == "city"),
    "obj"
  )
  return (
    <div className="newroom-component offset-1">
      <PhotoModal />
      {language && (
        <>
          <div className="subtitle">
            <Trans>Les chambres cotées ville :</Trans>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {roomsData
              .filter(obj => obj[language])[0]
              [language].filter(obj => obj["type"] == "city")
              .map(dataObj => {
                return (
                  <RoomCardComponent
                    photos={dataObj.photos}
                    description={dataObj.description}
                    title={dataObj.title}
                    superficie={dataObj.superficie}
                    subtitle={dataObj.subtitle}
                    occupation={dataObj.occupation}
                    sdb={dataObj.sdb}
                  />
                )
              })}
          </div>
          <div className="subtitle">
            <Trans>Les chambres avec vue Château :</Trans>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {roomsData
              .filter(obj => obj[language])[0]
              [language].filter(obj => obj["type"] == "castle")
              .map(dataObj => {
                return (
                  <RoomCardComponent
                    photos={dataObj.photos}
                    description={dataObj.description}
                    title={dataObj.title}
                    superficie={dataObj.superficie}
                    subtitle={dataObj.subtitle}
                    occupation={dataObj.occupation}
                    sdb={dataObj.sdb}
                  />
                )
              })}
          </div>
          <div className="subtitle">
            <Trans>Les chambres avec une terrasse privée :</Trans>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {roomsData
              .filter(obj => obj[language])[0]
              [language].filter(obj => obj["type"] == "garden")
              .map(dataObj => {
                return (
                  <RoomCardComponent
                    photos={dataObj.photos}
                    description={dataObj.description}
                    title={dataObj.title}
                    superficie={dataObj.superficie}
                    subtitle={dataObj.subtitle}
                    occupation={dataObj.occupation}
                    sdb={dataObj.sdb}
                  />
                )
              })}
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({ language, isTouchScreen }) => {
  return { language, isTouchScreen }
}

export default connect(mapStateToProps, null)(NewRoomComponent)
